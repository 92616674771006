<template>
  <div class="bd-email-section">
    <el-card class="bd-email-card">
      <div slot="header" class="card-header">
        <span>AI BD邮件生成</span>
        <el-select
          v-if="filterType === 'filter'"
          v-model="statusFilter"
          placeholder="状态筛选"
          clearable
          @change="handleStatusFilterChange"
          style="float: right; width: 140px"
        >
          <el-option label="全部" value="All" />
          <el-option label="待发送" value="Pending" />
          <el-option label="已发送" value="SENT" />
          <el-option label="已取消" value="CANCELLED" />
        </el-select>
      </div>
      <div class="bd-email-content">
        <el-table
          v-loading="loading"
          :data="mailList"
          style="width: 100%">
          <el-table-column
            prop="mail_title"
            label="邮件主题"
            min-width="200">
            <template slot-scope="scope">
              {{ scope.row.mail_title }}
              <div v-if="filterType === 'filter'" style="color: #909399; font-size: 13px;">
                To: {{ scope.row.contact_name }} - {{ scope.row.contact_email }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="mail_status" 
            label="状态"
            width="200">
            <template slot-scope="scope">
              {{ scope.row.mail_status }}
              <div>{{ scope.row.mail_status === 'SENT' ? ' (' + new Date(scope.row.send_time * 1000).toLocaleString() + ')' : '' }}</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="create_time"
            label="创建时间"
            width="180">
            <template slot-scope="scope">
              {{ new Date(scope.row.create_time * 1000).toLocaleString() }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="180">
            <template slot-scope="scope">
              <el-button
                type="text"
                @click="handleViewDetails(scope.row)"
              >
                查看详情
              </el-button>
              <el-button
                v-if="scope.row.mail_status === 'Pending'"
                type="text"
                @click="handleSendEmail(scope.row)"
              >
                发送邮件
              </el-button>
              <el-dropdown trigger="click">
                <span class="el-dropdown-link">
                  <i class="el-icon-more"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>
                    <span @click="handleShelveEmail(scope.row)">搁置</span>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <span style="color: #F56C6C" @click="handleDeleteEmail(scope.row)">删除</span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>

    <el-dialog
      title="邮件详情"
      :visible.sync="emailDialogVisible"
      width="70%"
      :before-close="handleEmailDialogClose"
    >
      <email-details
        :email-data="selectedEmail"
        :client-context="emailClientContext"
        @download="handleAttachmentDownload"
      />
    </el-dialog>
  </div>
</template>

<script>
import { postAccountRequest } from '@/api/assistant'
import EmailDetails from './EmailDetails.vue'

export default {
  name: 'AiBdMailList',
  components: {
    EmailDetails
  },
  props: {
    contactId: {
      type: [String, Number],
      required: true
    },
    filterType: {
      type: String,
      default: 'filter',
      required: true
    }
  },
  data() {
    return {
      loading: false,
      mailList: [],
      emailDialogVisible: false,
      selectedEmail: {},
      emailClientContext: {
        interactions: []
      },
      statusFilter: 'All'
    }
  },
  created() {
    this.fetchMailList()
  },
  methods: {
    async fetchMailList() {
      this.loading = true
      try {
        let response;
        if (this.filterType === 'contactID') {
          response = await postAccountRequest('getAIMarketingBDMailsByContactId', {
            contact_id: this.contactId
          });
        } else {
          response = await postAccountRequest('getAIMarketingBDMailsByFilter', {
            filter: this.statusFilter !== 'All' ? { mail_status: this.statusFilter } : []
          });
        }
        this.mailList = response.data.data || []
      } catch (error) {
        this.$message.error('获取邮件列表失败')
        console.error('Error fetching mail list:', error)
      } finally {
        this.loading = false
      }
    },
    handleViewDetails(email) {
      this.selectedEmail = email
      this.emailDialogVisible = true
    },
    async handleSendEmail(email) {
      try {
        await this.$confirm('确定要发送这封邮件吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        
        await postAccountRequest('sendAIMarketingBDMail', {
          mail_id: email._id.$id
        })
        this.$message.success('邮件发送成功')
        this.fetchMailList()
      } catch (error) {
        if (error !== 'cancel') {
          this.$message.error('邮件发送失败')
          console.error('Error sending email:', error)
        }
      }
    },
    async handleShelveEmail(email) {
      try {
        await postAccountRequest('shelveAIBDMail', {
          mail_id: email.id
        })
        this.$message.success('邮件已搁置')
        this.fetchMailList()
      } catch (error) {
        this.$message.error('操作失败')
        console.error('Error shelving email:', error)
      }
    },
    async handleDeleteEmail(email) {
      try {
        await this.$confirm('确定要删除这封邮件吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        
        await postAccountRequest('deleteAIBDMail', {
          mail_id: email.id
        })
        this.$message.success('邮件已删除')
        this.fetchMailList()
      } catch (error) {
        if (error !== 'cancel') {
          this.$message.error('删除失败')
          console.error('Error deleting email:', error)
        }
      }
    },
    handleEmailDialogClose(done) {
      this.selectedEmail = {}
      done()
    },
    handleAttachmentDownload(attachment) {
      this.$emit('download', attachment)
    },
    handleStatusFilterChange() {
      this.fetchMailList()
    }
  }
}
</script>

<style lang="scss" scoped>
.bd-email-section {
  .bd-email-card {
    margin-bottom: 20px;
  }

  .bd-email-content {
    min-height: 300px;
  }

  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .el-dropdown-link {
    cursor: pointer;
    color: #409EFF;
  }
}
</style> 