<template>
  <div class="email-details">
    <el-tabs v-model="activeTab">
      <el-tab-pane label="邮件内容" name="content">
        <div class="email-header">
          <div v-if="!isEditing">
            <div class="header-item">
              <span class="label">发件人：</span>
              <span>{{ emailData.from }} ({{ emailData.fromName }})</span>
            </div>
            <div class="header-item">
              <span class="label">收件人：</span>
              <span>{{ emailData.contact_email }}</span>
            </div>
            <div class="header-item">
              <span class="label">主题：</span>
              <span>{{ emailData.mail_title }}</span>
            </div>
            <div class="header-actions">
              <el-button type="primary" size="small" @click="handleEdit">
                编辑邮件
              </el-button>
            </div>
          </div>
          <div v-else>
            <div class="header-item">
              <span class="label">主题：</span>
              <el-input v-model="editedEmail.mail_title" size="small" />
            </div>
            <div class="header-item">
              <span class="label">内容：</span>
              <el-tinymce
                v-model="editedEmail.mail_content"
                :height="300"
                :menubar="'file edit view insert format tools'"
                :toolbar="'undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist | removeformat'"
              />
            </div>
            <div class="header-actions">
              <el-button type="primary" size="small" @click="handleSave" style="margin-right: 10px">
                保存
              </el-button>
              <el-button size="small" @click="cancelEdit">
                取消
              </el-button>
            </div>
          </div>
        </div>
        <div v-if="!isEditing" class="email-content" v-html="emailData.mail_content" style="word-break: normal"></div>
      </el-tab-pane>

      <el-tab-pane label="开发策略" name="tactics">
        <div class="tactics-section">
          <div v-if="tactics.length">
            <div v-for="(tactic, index) in tactics" :key="index" class="tactic-item">
              <h4>{{ tactic.策略名称}}</h4>
              <p>{{ tactic.策略描述 }}</p>
            </div>
          </div>
          <div v-else class="empty-state">
            暂无相关策略
          </div>
        </div>
      </el-tab-pane>

      <el-tab-pane label="相关材料" name="materials">
        <div class="materials-section">
          <div v-if="emailData.materials_string">
            <div class="materials-content" v-html="emailData.materials_string">
            </div>
          </div>
          <div v-else class="empty-state">
            暂无相关材料
          </div>
        </div>
      </el-tab-pane>

      <el-tab-pane label="客户上下文" name="context">
        <div class="context-section">
          <div v-if="emailData.contact_info_string">
            <div class="context-content" style="white-space: pre-wrap">
              {{ emailData.contact_info_string }}
            </div>
          </div>
          <div v-else class="empty-state">
            暂无客户上下文信息
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { postAccountRequest } from '@/api/assistant'  // Updated import path
import Editor from '@tinymce/tinymce-vue'

export default {
  name: 'EmailDetails',
  components: {
    'el-tinymce': Editor
  },
  props: {
    emailData: {
      type: Object,
      required: true
    },
    clientContext: {
      type: Object,
      default: () => ({
        interactions: []
      })
    }
  },
  data() {
    return {
      activeTab: 'content',
      tacticsData: [],
      isEditing: false,
      editedEmail: {
        mail_title: '',
        mail_content: ''
      }
    }
  },
  computed: {
    tactics() {
      return this.getTactics()
    }
  },
  methods: {
    formatTime(timestamp) {
      if (!timestamp) return ''
      const date = new Date(typeof timestamp === 'number' ? timestamp * 1000 : timestamp)
      return date.toLocaleString()
    },
    downloadMaterial(material) {
      // Implement download logic
      this.$emit('download', material)
    },
    getTactics() {
      if (!this.emailData.tactic_string) {
        this.tacticsData = [];
        return [];
      }
      try {
        
        this.tacticsData = JSON.parse(this.emailData.tactic_string);
        console.log(this.tacticsData);
        return this.tacticsData;
      } catch (e) {
        console.error('Failed to parse tactics:', e);
        this.tacticsData = [];
        return [];
      }
    },
    handleEdit() {
      this.isEditing = true
      this.editedEmail = {
        mail_title: this.emailData.mail_title,
        mail_content: this.emailData.mail_content
      }
    },
    async handleSave() {
      try {
        const response = await postAccountRequest('updateAIMarketingBDMail', {
          id: this.emailData._id.$id,
          mail_title: this.editedEmail.mail_title,
          mail_content: this.editedEmail.mail_content
        })
        
        if (response.data.code === 0) {
          this.$message.success('邮件更新成功')
          this.emailData = response.data.data
          this.isEditing = false
        } else {
          this.$message.error(response.data.msg || '更新失败')
        }
      } catch (error) {
        console.error('Failed to update email:', error)
        this.$message.error('更新失败，请稍后重试')
      }
    },
    cancelEdit() {
      this.isEditing = false
      this.editedEmail = {
        mail_title: '',
        mail_content: ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.email-details {
  .email-header {
    padding: 20px;
    background: #f8f9fa;
    border-radius: 4px;
    margin-bottom: 20px;

    .header-item {
      margin-bottom: 10px;
      
      .label {
        font-weight: bold;
        margin-right: 10px;
      }
    }

    .header-actions {
      margin-top: 10px;
      text-align: right;
    }
  }

  .email-content {
    padding: 20px;
    min-height: 200px;
  }

  .tactics-section,
  .materials-section,
  .context-section {
    padding: 20px;

    .empty-state {
      text-align: center;
      color: #909399;
      padding: 30px;
    }
  }

  .material-item {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #eee;

    i {
      margin-right: 10px;
    }

    span {
      flex: 1;
    }
  }

  .interaction-history {
    max-height: 400px;
    overflow-y: auto;
  }
}
</style> 